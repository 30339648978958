import typography from './typography'

const spacingUnit = 14

// Generates kinda-logarithmic spacing map inspired by Refactor-UI recommendations.
// Usage: spacing.s1 = 14, spacing[s0.25] = 3 ...
const spacing = [
  0.25,
  0.5,
  0.75,
  1,
  1.5,
  2,
  3,
  4,
  6,
  8,
  12,
  16,
  24,
  32,
  40,
  48,
].reduce(
  (list, scale) => ({
    ...list,
    ['s' + scale]: Math.floor(scale * spacingUnit),
  }),
  {}
)

const maxContentWidth = 960
const sidebarWidth = 200
const customVariables = {
  spacing,
  sizes: {
    maxContentWidth,
    sidebarWidth,
  },
  customBreakpoints: {
    sidebar: `${maxContentWidth + spacing.s2 * 2 + sidebarWidth * 2}px`,
  },
}

export default {
  initialColorMode: 'light',
  colors: {
    text: '#111',
    background: '#fff',
    modes: {
      dark: {
        text: '#fff',
        background: '#000',
      },
    },
  },
  ...typography,
  ...customVariables,
}
