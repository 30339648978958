import React from 'react'
import propTypes from 'prop-types'
import Helmet from 'react-helmet'

export default function SpeakerDeck({ id, ratio = 1.77777777777778 }) {
  return (
    <>
      <Helmet>
        <script async src="//speakerdeck.com/assets/embed.js" />
      </Helmet>
      <div className="speakerdeck-embed" data-id={id} data-ratio={ratio} />
    </>
  )
}

SpeakerDeck.propTypes = {
  id: propTypes.string.isRequired,
  ratio: propTypes.number,
}
